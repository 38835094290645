<template>
  <!--
    The view for the EventMovieConfiguration-component.
  -->
  <div class="eventMovieVonfigurationView">
    <EventMovieConfiguration :installation-id="installationId" />
  </div>
</template>

<script>
export default {
  name: 'EventMovieConfigurationView',
  components: {
    EventMovieConfiguration: () => import('@/components/EventMovieConfiguration.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('eventMovie') + ' Configuration'
    }
  }
}
</script>
